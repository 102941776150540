$(document).ready(function(){

	if($('.main-slider').length) {
		$('.main-slider').bxSlider({
		 	pager: false,
		 	adaptiveHeight: true
		});
	}	

	if($('.slider-sale').length) {
		$('.slider-sale').bxSlider({
			mode: 'fade',
		 	pager: false,
		 	adaptiveHeight: true,
		 	responsive: true
		});
	}	

	if($('.gallery-slider').length) {
		$('.gallery-slider').slick({
			centerMode: true,
			centerPadding: '0',
			slidesToShow: 3,
			responsive: [
		    	{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true
					}
		    	},
		    	{
			      	breakpoint: 800,
			      	settings: {
		        		slidesToShow: 2,
		        		slidesToScroll: 1
		      		}
		    	},
		    	{
		      		breakpoint: 640,
		      		settings: {
		        		slidesToShow: 1,
		        		slidesToScroll: 1,
		        		centerMode: false
		      		}
		    	}
		  	]
		});
	}	

	if($('.audio-player').length) {
		$('.audio-player audio').mediaelementplayer({
	        alwaysShowControls: true,
	        features: ['playpause','volume','progress','duration'],
	        audioVolume: 'horizontal',
	        audioWidth: 292,
	        audioHeight: 40,
	        showTimecodeFrameCount:false
	    });

	    $('.c-audio').on('click', '.btn.btn_primary', function(e){
	    	e.preventDefault();
	    	$(this).siblings('.cols-audio').slideToggle().toggleClass('hidden');
	    });
	}    

    $('.btn-gurger').on('click', function(){
    	$(this).toggleClass('is-active');
    });

    $('.b-article__text').on('click', '.toggle-text', function(e){
    	e.preventDefault();
   		$(this).fadeOut();
   		$('.b-article__hidden').slideDown();
    });

    $('.b-article__hidden').on('click', '.toggle-text-add', function(e){
    	e.preventDefault();
   		$('.b-article__hidden').slideUp();
   		$('.b-article__text').find('.toggle-text').fadeIn();
    });

    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && $(window).width() > 1024) {
	    $('.main-header').stickMe({
	    	topOffset: 200
	    });
	} else {
		$('.c-main-nav').stickMe({
	    	topOffset: 200
	    });
	}

    if($('.slider-gallery').length) {
	    $('.slider-gallery').owlCarousel({
	        pagination: false,
	        navigation: true,
	        scrollPerPage : false,
	        slideSpeed: 0,
	        paginationSpeed: 0,
	        rewindSpeed: 0,
	        items : 4,
	        lazyLoad: true,
	        itemsCustom : false,
	        itemsDesktop : [1199,4],
	        itemsDesktopSmall : [980,3],
	        itemsTablet: [768,2],
	        itemsTabletSmall: false,
	        itemsMobile : [479,1],
	        singleItem : false,
	        itemsScaleUp : false,
	    });  
	}    

	$('.fancybox').fancybox({
		padding: 0,
  		scrolling   : 'hidden',
        helpers: {
        	overlay: {
          		locked: false 
        	}
       	}
	});
});